.App {
  text-align: center;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: blue;

}

.Logo {
    width: 200px;
    height: 200px;
}

.downloadButton {
  margin-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 300px) and (max-width: 380px) {
  .Pdf {
    width: 310px !important;
  }
  .react-pdf__Page:first-child > canvas {
    width: 310px !important;
    height: 440px !important;
  }
  .react-pdf__Page:last-child > canvas {
    width: 310px !important;
    height: 440px !important;
  }
  .react-pdf__Page__canvas {
    width: 310px !important;
    height: 220px !important;
  }

}

@media (min-width: 381px) and (max-width: 480px) {
  .Pdf {
    width: 360px !important;
  }
  .react-pdf__Page:first-child > canvas {
    width: 310px !important;
    height: 500px !important;
  }
  .react-pdf__Page:last-child > canvas {
    width: 310px !important;
    height: 500px !important;
  }
  .react-pdf__Page__canvas {
    width: 360px !important;
    height: 256px !important;
  }
}

@media (min-width: 620px) and (max-width: 780px) {
  .Pdf {
    width: 600px !important;
  }
  .react-pdf__Page:first-child > canvas {
    width: 310px !important;
    height: 710px !important;
  }
  .react-pdf__Page:last-child > canvas {
    width: 310px !important;
    height: 710px !important;
  }
  .react-pdf__Page__canvas {
    width: 600px !important;
    height: 355px !important;
  }
}

@media (min-width: 1200px) {
  .Pdf {
    width: 1000px !important;
  }
  .react-pdf__Page__canvas {
    width: 700px !important;
    height: 900px !important;
  }
}